<template>
  <v-row
    class="px-0 mx-0"
    style="background-color: #ffffff;"
  >
    <v-col
      cols="12"
      sm="6"
      class="px-0 mx-0"
      :class="add_class"
    >
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        max-width="800"
        width="90%"
      >
        <base-subheading
          weight="regular"
          :title="subheading"
          class="text-center font-weight-bold"
          :class="color"
        />
        <br>
        <base-heading
          v-if="heading"
          :size="size"
          :title="heading"
          weight="medium"
          :class="color"
          class="text-center"
        />
        <div
          v-else-if="html"
          v-html="html"
          :class="color"
          class="mx-10"
        ></div>
        <br>
        <div
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
          class="d-flex flex-wrap align-center justify-center"
        >
          <base-btn
            v-if="link"
            @click=fnGoTo(link)
          >
            {{link_desc}}
          </base-btn>
        </div>
      </v-responsive>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      class="px-0 py-0 mx-0"
    >
      <v-img
        :src="src"
        :height="$vuetify.breakpoint.mdAndUp ? height : 225"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseRightPhoto',

    props: {
      add_class: {
        type: String,
      },
      src: {
        type: String,
        default: '',
      },
      subheading: {
        type: String,
        default: 'default',
      },
      heading: {
        type: String,
      },
      height: {
        type: Number,
        default: 400,
      },
      size: {
        type: String,
        default: 'text-h2',
      },
      html: {
        type: String,
      },
      color: {
        type: String,
        default: 'white--text',
      },
      link: {
        type: String,
      },
      link_desc: {
        type: String,
      },
    },
    methods: {
      fnGoTo (link) {
        this.$router.push(link)
      },
    },
  }
</script>
