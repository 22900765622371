<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          cols="1"
          class="text-center mx-auto my-auto py-auto"
        >
          <base-icon
            :color="color"
            size="40"
          >
            {{ icon }}
          </base-icon>
        </v-col>
        <v-col
          cols="2"
        />
        <v-col
          cols="9"
          class="text-center mx-auto my-auto py-auto"
        >
          <base-title
            size="text-h5"
            :title="title"
            class="text-center"
            space="1"
            style="letter-spacing: 0.0rem !important;"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseInfoCardPacific',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      subtitle: String,
      text: String,
      title: String,
    },
  }
</script>
