// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#214987',
        secondary: '#323232',
        accent: '#00c1f3',
        third: '#e86f6f',
        fourth: '#e87be8',
      },
      dark: {
        primary: '#323232',
        secondary: '#323232',
        accent: '#323232',
      },
    },
  },
})
