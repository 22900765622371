<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/howellcare_logo.png')"
        contain
        max-width="200"
        min-height="100"
        width="100%"
      />

      <base-title
        size="text-h5"
        space="4"
        title="HOWELLCARE INDUSTRIES SDN BHD"
        weight="regular"
      />
      <base-title
        class="grey--text"
        size="text-h6"
        space="4"
        title="1313838-X"
        weight="regular"
      />

      <base-body>
        HOWELLCARE INDUSTRIES SDN. BHD. (HISB) was incorporated in 2019 with principal business activities comprising trading of all types of Natural Rubber Gloves and synthetic rubber Gloves, supply of equipment and machinery related to the Glove Industry, Factory Procurement and Manufacturing Machinery and Production Engineering.
        HISB provides engineering and commercial consultancy services on glove factory development projects including the scope of design, construction and machine assembly for glove industry customers.
      </base-body>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfoPacific',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>
